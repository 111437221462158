:root {
  --primary-color: #fff;
  --primary-color2: #1f1f2c;
  --primary-color3: #5142fc;
  --primary-color4: #7a798a;
  --primary-color5: #f8f8f8;
  --primary-color6: #14141f;
  --primary-color7: #f8f8f8;
  --bg-section2: #f8f8f8;
  --primary-color8: #7a798a;
  --primary-color9: #7a798a;

  --default-light-banner-url: url(../images/default-light-banner.jpg);
  --profile-light-banner-url: url(../images/profile-light-banner.jpg);
}
